<!--  -->
<template>
  <!-- 投资者关系 -->
  <div class="home page-template page-template-template-home page-template-template-home-php page page-id-11 wpb-js-composer js-comp-ver-6.4.2 vc_responsive">
      <Headers></Headers>
       
    <div class="inside-banner">
      <div class="image"><img :src="require('./../../public/page1/20181213091506_445646.jpg')" /></div>
    </div>
        <div class="full-inside-nav">
        <div class="commonweb">
            <div class="inside-nav-wrapper">
                <div class="inside-nav-swiper">
                    <ul class="swiper-wrapper">


                        <li class="swiper-slide cur"><a href="index.htm">投资者保护宣传 </a></li>


                        <li class="swiper-slide "><a href="#">联系我们 </a></li>


                        <li class="swiper-slide "><a href="#">行情走势 </a></li>


                        <li class="swiper-slide "><a href="#">十大股东 </a></li>


                        <li class="swiper-slide "><a href="#">季度报告 </a></li>


                        <li class="swiper-slide "><a href="#">公司公告 </a></li>


                        <li class="swiper-slide "><a href="#">公司章程 </a></li>


                        <li class="swiper-slide "><a href="#">财务指标 </a></li>


                        <li class="swiper-slide "><a href="#">投资者交流互动 </a></li>


                        <li class="swiper-slide "><a href="#">投资者关系活动记录表 </a></li>

                    </ul>
                </div>
                <div class="inside-nav-botton inside-nav-prev" style="display:block"></div>
                <div class="inside-nav-botton inside-nav-next" style="display:block"></div>
            </div>
        </div>
    </div>
        <router-view/>
        <Footers></Footers>
  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import Headers from './../components/header/header.vue';
import Footers from './../components/footer/footer.vue';
import "./../../public/page1/jquery-1.12.4.min.js";
import "./../../public/page1/swiper.jquery.min.js";
export default {
  //import引入的组件需要注入到对象中才能使用
 components: {Headers,Footers},
  data() {
    //这里存放数据
    return {};
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {},
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {},
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {
        $(function () {
        var wow = new WOW({
            boxClass: "wow",
            animateClass: "animated",
            offset: 0,
            mobile: true,
            live: true,
        });
        wow.init();
        });
        var insidekSwiper = new Swiper('.inside-nav-swiper', { 
	        paginationClickable: true,
	        slidesPerView: 6,
	        spaceBetween: 0,
	        //autoplay:1200,
	        //speed:800,
	        loop:false,
	        //delay:0,
	        nextButton: '.inside-nav-next',
        	prevButton: '.inside-nav-prev',
        	simulateTouch: false,
        	//freeMode:true,
        	//autoplayDisableOnInteraction: false,
	        breakpoints: {
//	        	1199:{
//	        		slidesPerView: 4.8,
//	        	},
	            1023: {
	                slidesPerView: 3.2,
	            },
	            767: {
	                slidesPerView: 2.4,
	            },
	            640: {
	                slidesPerView: 1.8,
	            },
	            321: {
	                slidesPerView: 1.5,
	            }
	        },
	        onInit:function(swiper){

        		if($(window).prop('outerWidth') >= 1024){
					
					if(swiper.slides.length <= 6){
				    	
				    	$('.inside-nav-swiper ul').removeClass('flex')
				    	swiper.destroy(false);
				    }else{
				    	$('.inside-nav-botton').show(0);
				    	$('.inside-nav-swiper ul').addClass('flex');
				    }
					
				}
	        }
        });
        	    var hotnews07Swiper = new Swiper('.hotnewsTop', {
	    	        paginationClickable: true,
	    	        spaceBetween: 0,
	    	        autoplay:3200,
	    	        speed:800,
	    	        loop:true,
	            	simulateTouch: false,
	            	pagination: '.shotnewsbtn07',
                });
                
  },
  beforeCreate() {}, //生命周期 - 创建之前
  beforeMount() {}, //生命周期 - 挂载之前
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
  activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
};
</script>
<style lang='less' scoped>
//@import url(); 引入公共css类
@import url("./../../public/page1/animate.min.css");
@import url("./../../public/page1/layout.css");

.inside-banner {
    padding: 169px 0 50px;
}
@media screen and (min-width: 768px) and (max-width: 991px){
    body .inside-banner {
        padding: 119px 0 33px;
    }
}
@media screen and (max-width: 767px){
    body .inside-banner {
        padding: 0 0 21px;
    }
}

</style>